@import '../styles/variables.scss';


.form-container{
    width: 70%;
    max-width: 560px;
    align-items: center;
    text-align: center;
}

p{
    color:$darkGreyEgg;
    line-height: 1.4rem;
}

.form{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.fieldContainer{
    position: relative;
    width: 100%;
}

.fieldIcon{
    position: absolute;
    width: 15px;
    opacity: .7;
    top:32px;
    left: 30px;
}

.field{
    width: 100%;
    height: 60px;
    margin:10px;
    padding:0 10px;
    border:1px solid lightgray;
    border-radius: 20px;
    font-size: 1rem;
    background-color: whitesmoke;
    padding-left: 50px;
    box-sizing: border-box;
}

.submit{
    background-color: $eggColor;
    width: 150px;
    margin:0 auto;
    height: 60px;
    border-radius:50px;
    border:none;
    margin-top: 20px;
    &:disabled{
        opacity: .5;
    }
    &:hover{
        opacity: .8;
    }
    cursor:pointer;
}