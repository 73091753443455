.container{
    background-color:white;
    box-sizing: border-box;
    height: 100vh;
    // padding:20px;
    display: flex;
    .left{
        background: url('../assets/images/banner.jpg');
        background-size: cover;
        background-position: right center;
        flex-basis: 50%;
        background-color: white;
        display: none;
        flex-direction: column;
        @media only screen and (min-width : 768px) {
            display: flex;
        }
        .imgContainer{
            flex-basis:25%;
            display: flex;
            align-items: center;
            justify-content: center;
            img{
                width: 154px;
            }
        }
        .cardContainer{
            flex-basis:75%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            .leftCard{
                // margin-top: 40px;
                background-color: white;
                border-radius: 15px;
                box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.08);
                width: 65%;
                padding:80px 50px;
                text-align: center;
            }
        }
    }
    .right{
        flex-basis: 100%;
        @media only screen and (min-width : 768px) {
            flex-basis: 50%;
        }
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}
