.container{
    background: url('../assets/images/background.jpg');
    background-size: cover;
    background-position: center left;
    height: 100vh;
    box-sizing: border-box;
    // margin:20px;
    .cardContainer{
        // flex-basis:75%;
        // background-color: bisque;
        height: 100%;
        width: 50%;
        display: none;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @media only screen and (min-width : 768px) {
            display:flex;
        }
        .leftCard{
            margin-top: 40px;
            background-color: white;
            border-radius: 15px;
            box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.08);
            width: 65%;
            padding:80px 50px;
            text-align: center;
            .schedule{
                // background-color: red;
                border-radius:15px;
                border:1px solid lightgrey;
                display: flex;
                div{
                    flex-basis: 60%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    &:last-child{
                        flex-basis: 40%;
                        display: flex;
                        flex-direction: column;
                        font-weight: bold;
                        padding:20px;
                        box-sizing: border-box;
                        a{
                            margin-top: 20px;
                        }
                    }
                }
                ul{
                    font-size: .8rem;
                    margin:0;
                    padding:0;
                    display: flex;
                    flex-direction: column;
                    border-right: 1px solid lightgrey;
                    li{
                        strong{
                            margin-bottom: 5px;
                        }
                        width: 100%;
                        padding:10px;
                        box-sizing: border-box;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        &:not(:last-child){
                            border-bottom: 1px solid lightgrey;
                        }
                    }
                }
            }
        }
    }
}


.camButton{
    background-color: white;
    border:1px solid lightgrey;
    padding:10px 20px;
    border-radius:15px;
    display: block;
    cursor:pointer;
    box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.12);
    &:hover{
        background-color: lightgrey;
    }
}



.widgetFrame{
    /* background-color: red; */
    width: 90%;
    height: 70%;
    display: block;
    position: absolute;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.08);
    background-color: white;
    border:none;
    bottom:30px;
    right:5%;
    border-radius:20px;
    @media only screen and (min-width : 768px) {
        right:50px;
        width: 380px;
        height: 560px;
        bottom:50px;
    }
}


.logoutContainer{
    position: absolute;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:10px 20px;
    box-sizing: border-box;
}

.logoutButton{
    // position:absolute;
    // width: 100%;
    color:white;
    background:none;
    border:none;
    top:30px;
    font-weight: bold;
    font-size: 1rem;
    cursor: pointer;
    &:hover{
        color: darken(white, 20%)
    }
    @media only screen and (min-width : 768px) {
        width: auto;
        top:30px;
        left:30px;
    }
}

.logoContainer{
    position: absolute;
    right:calc(50% - 78px);
    top:12.5%;
    height: 80px;
    width: 154px;
    background: url('../assets/images/event_logo_light.svg');
    background-repeat: no-repeat;
    background-size: contain;
    @media only screen and (min-width : 768px) {
        width: 150px;
        background: url('../assets/images/event_logo_dark.svg');
        background-repeat: no-repeat;
        right:55px;
        top:3%;
    }
    img{
        width: 400px;
    }
}