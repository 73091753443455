$eggColor: #ffcd00;
$darkGreyEgg: #37474f;
$mediumGreyBg: #d2d2d2; //D1D1D1
$lightGreyBg: #f8f8f8; //F8F8F8
$paragraphColor: $darkGreyEgg;

$separadorLineas: #eaeaea; //EAEAEA
$borders: lighten($mediumGreyBg, 10%);

$border-radius-lg: 20px;
$border-radius-md: 15px;
$border-radius-sm: 10px;

$header-height: 70px;
$footer-height: 90px;

@mixin sombra {
    -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.09);
    -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.09);
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.09);
}

.grey-bg {
    background: $lightGreyBg;
}

.egg-color-bg {
    background: $eggColor;
}

.responsive {
    flex-direction: column;
    @media screen and (min-width: 768px) {
        flex-direction: row;
    }
}
