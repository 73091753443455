@import-normalize;
@import './variables.scss';

.demo-site-container{
    margin:0;
    padding:0;
    box-sizing: border-box;
}

.form-container{
    min-width: 50%;
    min-height: 60%;
    background-color: white;
    border-radius: 20px;
    display: flex;
    .right-side{
        padding:60px;
        flex-basis:100%;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        form{
            display: flex;
            flex-direction: column;
            width: 100%;
            input,button{
                height: 55px;
                margin:10px;
                padding:10px;
                border:1px solid lightgray;
                border-radius: 10px;
            }
        }
    }
}

